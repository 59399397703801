import React from 'react';
const NotFound = () => (
    <div className='page'>
    <div className='content'>
        <h2>Aradığınız sayfaya ulaşılamadı.</h2><br></br>
        <a className="link" href="/">Anasayfaya Dön</a>
    </div>
</div>
);

export default NotFound;